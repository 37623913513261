<template>
    <div class="app">
        <div class="video">
            <div class="video_fl" :style="{ height: Height + 'px' }">
                <!-- <video :src="VideoSrc" controls="controls" autoplay="autoplay" id="myVideo"
                    v-if="CookieType == 1"></video> -->
                <video :src="VideoSrc" controls="controls" autoplay="autoplay" id="myVideo"
                    controlslist='nodownload'></video>
                <!-- v-if="CookieType != 1" -->
            </div>
            <div class="video_fr" :style="{ height: Height + 'px' }">
                <div class="maxwidth">
                    <div class="title" @click="Handleloop()" v-if="listData[0]"> {{ listData[0].course_type_name }}
                    </div>
                    <div class="playlist-intro__detail">自制 · 共{{ listData.length }}集 · 9328热度</div>
                    <div class="scroll" :style="{ height: ListHeight + 'px' }">
                        <div class="flex_img" v-for="(item, index) in listData" :key="index"
                            @click="HandleSrc(item.url, item.id, index)" :class="grade !== index || 'active'">
                            <!-- <img src="https://puui.qpic.cn/vpic_cover/z0046p3ahlr/z0046p3ahlr_1688552178_hz.jpg/496?max_age=7776000" -->
                            <!-- alt=""> -->
                            <div class="flex_text">第{{ index + 1 }}节 {{ item.lesson_name }} <br> 时长：{{ item.video_length
                                }}分钟  <br> 日期：{{ item.created_at }}
                            </div>
                            <div class="playstate">播放中...</div>
                            <button @click="daonload(item.id)" v-if="CookieType == 1">下载</button>

                            <!-- <a :href="`${VideoSrc}`" download="1.mp4">下载</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SearchData, ConsumeVideoTime, DownWatch } from "@/api/home";
export default {
    name: "Video",
    data() {
        return {
            CookieType: this.$cookie.get('type'),
            grade: 0,
            Height: "",
            ListHeight: "",
            Num: "",
            VideoSrc: "",
            listData: [],
        }
    },


    mounted() {
        this.Height = document.body.clientHeight - 90;
        this.ListHeight = document.body.clientHeight - 190;
        this.HandleData();
    },
    methods: {
        // // 测试视频
        // const video_url = 'https://media.w3.org/2010/05/sintel/trailer.mp4';
        
        // 下载函数
        daonload(id) {
            DownWatch({ video_id: id }).then(res => {
                if (res.code == 200) {
                    window.location.href = `${res.data.url}`;
                }
            });
        },

        Handleloop() {
            var video = document.getElementById('myVideo');
            var currentTime = video.currentTime;
            console.log('当前视频播放的时间：', currentTime);
        },
        HandleData() {
            var route = this.$route.query.id;
            SearchData({ type_id: route }).then(res => {
                if (res.code == 200) {
                    this.listData = res.data;
                    this.Num = res.data;
                    document.title = `${res.data[0].course_type_name} - 视频学习平台 `
                }
            });
        },
        HandleSrc(src, id, index) {
            ConsumeVideoTime({ video_id: id }).then(res => {
                if (res.code == 200) {
                    this.VideoSrc = res.data.url;
                    this.grade = index
                }

                if (res == 500) {
                    this.VideoSrc = "";
                    this.$message({
                        showClose: true,
                        message: "观看次数已耗尽，请联系相关服务老师",
                        type: "error",
                    });
                    return;
                }
            });
        }
    },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-thumb {

    background-color: #ccc;
}

.video {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .video_fl {
        width: 80%;
        height: 600px;
        background: #333;

        video {
            width: 100%;
            height: 770px;
            background: #333;
            object-fit: cover;
        }
    }

    .video_fr {
        width: 19%;
        background: #333;
        height: 760px;
        position: relative;

        .scroll {
            width: 100%;
            overflow-y: scroll;
            height: 100%;
        }

        .maxwidth {
            width: 90%;
            margin: 0 auto;

            .flex_img {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
                cursor: pointer;
                position: relative;

                .playstate {
                    color: #0d9e72;
                    font-size: 14px;
                    font-weight: 500;
                    width: 70px;
                    display: none;
                }

                img {
                    width: 120px;
                    height: 60px;
                    object-fit: cover;
                    margin-right: 10px;
                }

                .flex_text {

                    font-size: 14px;
                    line-height: 20px;
                    color: #fff;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    // overflow: hidden;
                    text-overflow: ellipsis;
                    padding-bottom: 10px;
                }

                .flex_text:hover {
                    color: #0d9e72;
                }
            }

            .flex_img.active .flex_text {
                color: #0d9e72;
            }

            .flex_img.active .playstate {
                display: block;
            }

            .title {
                font-size: 24px;
                line-height: 34px;
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 16px 0 6px 0;
                color: #fff;
            }

            .playlist-intro__detail {
                color: #8a8a8a;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
</style>